.section-six .section-title:nth-child(1) {
    margin-top: 35%;
}

.section-six-image {
    display: flex;
    justify-content: center;
    padding: 10% 0% 5%;
}

@media screen and (max-width: 414px) {
    .section-six .section-title:nth-child(1) {
        margin-top: 0%;
    }
}
