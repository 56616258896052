.section-form {
  background: var(--color-primary-dark);
  color: #ffffff !important;
}

.section-form input:focus,
.section-form textarea:focus {
  outline: none;
  border-color: #ffffff50;
  box-shadow: 0 2px 4px #ffffff50;
  -moz-box-shadow: 0 2px 4px #ffffff50;
  -webkit-box-shadow: 0 2px 4px #ffffff50;
}

.section-form .form-label,
.section-form .btn {
  font-family: "acumin variable italic", sans-serif;
  font-weight: 300;
}


.section-form .form-sendmail-successfull{
  margin-left: 1% !important;
  padding: 8.5px !important;
  color: #3BD18E;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-decoration: solid;
}

.section-form .form-sendmail-error{
  margin-left: 1% !important;
  padding: 8.5px !important;
  color: #DDD71D;
  font-weight: 500;
  letter-spacing: 0.1rem;
  text-decoration: solid;
}
