.section-eight {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    background: var(--color-primary);
    padding: 5% 10%;
}

.section-eight {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    text-align: center;
}

.section-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2% 15%;
}

.section-icon-item {
    margin: 5%;
}

.section-list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.section-list-item a {
    margin-left: 5px;
}

.section-list-item span:nth-child(1) {
    width: 36px;
    height: auto;
    padding: 10px;
}

.section-list-item span:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: start;
    padding-left: 5px;
    vertical-align: super;
    margin: auto 1%;
}

@media screen and (max-width: 540px) {
    .section-eight {
        padding: 10% 0%;
    }
    .section-eight .section-paragraph {
        font-size: 18px !important;
    }
    .section-eight .section-list li {
        margin: auto;
        font-size: 16px;
    }
    .section-list-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 5px;
    }
    .section-list-item span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 16px;
    }
    .section-footer {
        font-size: 16px;
        margin: auto;
        text-align: center;
    }
}
