body{
    margin: 0 !important;
}

.floating-btn {
    position: fixed;
    bottom: 20px !important;
    right: 20px !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    z-index: 1;
}

.section-bg-primary {
    position: relative;
    background: var(--color-primary);
}

.text-color-primary {
    font-family: "acumin variable italic", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-primary) !important;
}

.section-bg-primary .mask {
    position: absolute;
    bottom: 0%;
    left: 0%;
    right: 0%;
    background-color: var(--color-primary-dark);
    mask-image: url("../assets/img/mask.png");
    height: 448px;
    z-index: 0;
}

.section-bg-secondary {
    position: relative;
    background: var(--color-secondary);
}

.section-bg-secondary .mask {
    position: absolute;
    bottom: 0%;
    left: 0%;
    right: 0%;
    background: var(--color-secondary-dark);
    mask-image: url("../assets/img/mask.png");
    height: 448px;
}

.section-title {
    font-family: "acumin variable black italic", sans-serif;
    font-size: 56px;
    font-style: italic;
}

.section-paragraph {
    font-family: "acumin variable italic", sans-serif;
    font-size: 28px;
    font-weight: 300;
    line-height: 33px;
}

.section-paragraph .bold {
    font-weight: bold;
}

.section-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 5px;
}

.section-list li {
    display: block;
    text-align: start;
    margin-bottom: 10px;
}

.section {
    padding: 10% 5%;
}

.section-subtitle {
    font-family: "acumin variable black italic", sans-serif;
    font-size: 26px;
    font-style: italic;
    margin-bottom: 0%;
}

.section-image {
    padding: 5%;
}

.section-footer {
    padding: 1% 10%;
    background-color: var(--color-primary-dark);
    font-family: "acumin variable italic", sans-serif;
    font-size: 24px;
    color: #ffffff;
    word-break: keep-all;
}

@media screen and (max-width: 1280px) {
    .section-title {
        font-size: 40px;
    }
    .section-paragraph {
        font-size: 24px !important;
    }
    .section-footer {
        font-size: 18px;
    }
}

@media screen and (max-width: 1024px) {
    .section-footer {
        font-size: 18px;
        text-align: center;
        margin: auto;
    }
}

@media screen and (max-width: 820px) {
    .section-title {
        font-size: 26px;
    }
    .section-paragraph {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 520px) {
    .section-title {
        font-size: 26px !important;
    }
    .section-paragraph {
        font-size: 16px !important;
        line-height: 20px !important;
        min-width: 280px;
    }
    .section-two-actions {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .section-two-actions img {
        padding: 5%;
    }
    .section-two-actions ul {
        display: block;
        margin: auto 25%;
        line-height: 35px;
        margin-top: 1%;
    }
    .section-subtitle {
        margin-top: 10px !important;
    }
    .section-list {
        font-size: 18px !important;
    }
    .section-footer {
        font-size: 14px;
        text-align: center;
        margin: auto;
    }
}
