.header {
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
    width: 100%;
    height: auto;
    padding: 25px;
    justify-content: space-around;
    background-color: var(--color-primary);
}

.header div {
    display: block;
    text-align: center;
}

.header div:nth-child(2) {
    text-align: start;
    vertical-align: super;
}

.header div:nth-child(3) {
    text-align: end;
}

.horizontal-divider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: auto;
}

.horizontal-divider span {
    display: block;
    width: 99%;
    height: 2px;
    background: #fff;
}

.navbar {
    margin: 5px;
    background-color: var(--color-primary) !important;
    margin-bottom: 0%;
}

.navbar a {
    text-align: center;
    padding: 10px 5px;
    color: #c9c9c9;
}

.navbar .navbar-brand:hover {
    opacity: 0.9 !important;
}

.navbar a.active {
    color: #ffffff;
    background-color: var(--color-primary-dark);
    border-radius: 4px;
    border: solid 1px #ffffff15;
}

@media screen and (max-width: 1280px) {
    .header {
        display: grid !important;
        grid-template-columns: 2fr 4fr 2fr;
        justify-content: center;
        align-content: space-around;
        padding: 2% 1% !important;
    }
    .header div {
        display: flex !important;
        justify-content: center;
    }
}

@media screen and (max-width: 820px) {
    .header {
        display: grid !important;
        grid-template-columns: 2fr 2fr 2fr;
        justify-content: center;
        align-content: space-around;
    }
}

@media screen and (max-width: 540px) {
    .header {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-content: space-around;
        flex-wrap: wrap;
    }
    .header div {
        padding-bottom: 10px !important;
    }
}
