.slider-title {
    position: absolute;
    top: -650px;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    font-family: "acumin variable black italic", sans-serif;
    font-size: 56px;
}

.slider-subtitle {
    position: absolute;
    top: -500px;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    font-family: "acumin variable italic", sans-serif;
    font-size: 28px;
}

.slider-button {
    position: absolute;
    top: -600px;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    max-width: 256px;
    height: 54px;
    background: #5937e1;
    border: none;
    border-radius: 50px;
    font-family: "acumin variable italic", sans-serif;
    font-size: 22px;
    align-content: center;
    font-weight: 300;
    color: #ffffff;
    padding-top: 2px;
    box-shadow: #ffffff50 0px 2px 4px;
}

.slider-button:hover {
    opacity: 0.9;
}

@media screen and (max-width: 1280px) {
    .slider-title {
        top: -375px;
        font-size: 36px;
    }
    .slider-subtitle {
        top: -275px;
        font-size: 22px;
    }
    .slider-button {
        top: -325px;
        font-size: 16px;
        max-width: 192px !important;
        height: 36px !important;
    }
}

@media screen and (max-width: 1024px) {
    .slider-title {
        top: -300px;
        font-size: 32px;
    }
    .slider-subtitle {
        top: -225px;
        font-size: 18px;
    }
    .slider-button {
        top: -275px;
        font-size: 16px;
        max-width: 192px !important;
        height: 36px !important;
    }
}
