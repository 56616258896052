.section-five {
    color: #ffffff;
}

.section-five .section-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.section-five .section-actions .section-action-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

@media screen and (max-width: 540px) {
    .section-five .section-actions .section-action-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        margin: auto;
        text-align: center;
    }
    .section-five .section-actions .section-action-item img {
        margin-bottom: 5%;
    }
}
