.section-seven {
    background-image: url("../../../assets/img/img5.webp");
    background-size: cover;
    color: #ffffff;
    filter: brightness(0.85);
}

@media screen and (max-width: 414px) {
    .section {
        padding: 10% 1%;
    }
    .section-seven .section-paragraph {
        word-break: break-all;
        font-size: 16px !important;
        margin: 0px;
        min-width: 340px !important;
    }
}
