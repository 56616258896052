.section-two {
    color: #ffffff;
}

.section-two .section-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.section-two .section-paragraph {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.section-two .section-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.section-two .section-action-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 5px;
}

.section-two .section-action-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
}

.section-two .section-action-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 195px;
}

.section-two .section-action-text .section-paragraph {
    display: block;
}

.section-two .section-paragraph .section-list {
    display: block;
    margin-top: 15px;
}

@media screen and (max-width: 540px) {
    .section-two .section-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .section-two .section-action-text {
        min-height: 0px !important;
    }
    .section-two .section-list li {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
