.section-four .section-title,
.section-four .section-paragraph {
    margin-left: 10%;
}

.section-four .section-title:nth-child(1) {
    margin-top: 15%;
}

@media screen and (max-width: 540px) {
    .section-four .section-title,
    .section-four .section-paragraph {
        padding-left: 0% !important;
    }
    .section-four .section-title:nth-child(1) {
        margin-top: 0% !important;
    }
    .section-four .section-paragraph {
        min-width: 280px !important;
    }
}

@media screen and (max-width: 360px) {
    .section-four .section-title,
    .section-four .section-paragraph {
        padding-left: 0% !important;
        margin: 0% !important;
    }
    .section-four .section-title:nth-child(1) {
        margin-top: 0% !important;
    }
    .section-four .section-paragraph {
        min-width: 280px !important;
    }
}
